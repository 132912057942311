<template>
  <v-main style="background: #F9F9F9;">
    <v-container fluid>
      <v-row justify="center" align="center" style="margin-top: 10%">
        <v-col cols="4">
          <v-img src="@/assets/login_bg.svg"/>
        </v-col>
        <v-col cols="12" sm="12" xs="12" md="6" lg="3" xl="2" xxl="2">
            <h1>ورود</h1>
            <v-card-text>
              <v-form @submit.prevent="login">
                <v-text-field outlined rounded v-model="phone_number" label="شماره موبایل" required></v-text-field>
                <v-text-field outlined rounded v-model="password" label="رمز عبور" type="password" required></v-text-field>
                <ul v-if="errors">
                  <li v-for="error in errors" class="red--text">
                    {{error.non_field_errors[0]}}
                  </li>
                </ul>
                <v-btn Large :disabled="!phone_number || !password" :loading="btn_loading" type="submit" style="background: linear-gradient(98deg, #156EBF 18.24%, #00519A 79.84%);" color="primary" block rounded-lg>ورود</v-btn>
              </v-form>
            </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      btn_loading: false,
      errors:null,
      phone_number: '',
      password: ''
    };
  },
  created() {
    if(localStorage.getItem('token')){
      this.get_info_and_redirect()
    }
  },
  methods: {
    get_info_and_redirect(){
      axios
          .get(`${this.BACKEND_LINK}/api/users/info/`,{
            headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
          })
          .then((response)=>{
            if(response.status === 200) {
              if(response.data.is_superuser){
                this.$router.push({name: 'admin'});
              }else{
                this.$router.push({name: 'dashboard'});
              }
            }
          })

    },
    login() {
      this.btn_loading = true;

      axios
          .post(`${this.BACKEND_LINK}/api/auth/login/`,{
            username:this.phone_number,
            password: this.password
          })
          .then((response)=>{
            if(response.status===200) {
              localStorage.setItem('token', response.data.token);
              this.get_info_and_redirect();
            }
          })
          .catch((e)=>{
            this.errors = e.response.data;
          })
          .finally(()=>{
            this.btn_loading = false;
          })
    }
  }

}
</script>
