<template>
    <v-container class="mt-10">
      <v-card outlined :loading="!user">
        <v-card-title>
          اطلاعات شخصی : 
        </v-card-title>
        <v-card-text>
          <v-row v-if="user">
            <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" xxl="4">
              <v-text-field v-model="user.first_name" label="نام" outlined dense
                            :error="errors&&errors.first_name?true:false" :error-messages="errors && errors.first_name?errors.first_name:[]"
              ></v-text-field>
           </v-col>
            <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" xxl="4">
              <v-text-field v-model="user.last_name" label="نام خانوادگی" outlined dense
                            :error="errors&&errors.last_name?true:false" :error-messages="errors && errors.last_name?errors.last_name:[]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" xxl="4">
              <v-text-field v-model="user.username" 
                            :error="errors&&errors.username?true:false" :error-messages="errors && errors.username?errors.username:[]"
                            label="نام کاربری" outlined dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" xxl="4">
              <v-text-field class="phone_input" v-model="user.phone_number" label="شماره موبایل" outlined dense
                            :error="errors&&errors.phone_number?true:false" :error-messages="errors && errors.phone_number?errors.phone_number:[]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" xxl="4">
              <v-text-field v-model="user.email" label="ایمیل" outlined dense
                            :error="errors&&errors.email?true:false" :error-messages="errors && errors.email?errors.email:[]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4" xxl="4">
              <v-btn @click="password.en=true" color="red" dark outlined block large
                >
                  تغیر گذرواژه
                </v-btn>
            </v-col>
            </v-row>
        </v-card-text>
      </v-card>
      <div class="submit-box">
          <v-btn
                  style="border: 10px white solid;background: linear-gradient(98deg, rgb(21, 110, 191) 18.24%, rgb(0, 81, 154) 79.84%);"
                  dark rounded x-large size="x-large" flat class="my-auto" outlined @click="send_data()"
                  :loading="btn_loading"
                  >
                ثبت اطلاعات شخصی 
                </v-btn>
        </div>
    <v-dialog v-model="password.en" scrollable height="10vh" width="30em">
      <v-card>
        <v-card-title>
          <h3 class="mx-auto">تغیر گذرواژه</h3>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-container>
            <v-text-field  @click:append="pass_show.old = !pass_show.old" :append-icon="pass_show.old ? 'mdi-eye' : 'mdi-eye-off'" :type="pass_show.old ? 'text' : 'password'" v-model="password.old" :error-messages="password.errors['old_password']" rounded outlined label="گذرواژه قبلی"></v-text-field>
            <v-text-field  @click:append="pass_show.new_1 = !pass_show.new_1" :append-icon="pass_show.new_1 ? 'mdi-eye' : 'mdi-eye-off'" :type="pass_show.new_1 ? 'text' : 'password'" v-model="password.new_1" rounded outlined label="گذرواژه جدید"></v-text-field>
            <v-text-field  @click:append="pass_show.new_2 = !pass_show.new_2" :append-icon="pass_show.new_2 ? 'mdi-eye' : 'mdi-eye-off'" :type="pass_show.new_2 ? 'text' : 'password'" v-model="password.new_2" :error-messages="password.errors['new_password']" rounded outlined label="گذرواژه جدید"></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding: 0 !important;"
        v-if="(password.old && password.new_1 && password.new_2) && (password.new_1 === password.new_2)">
          <v-btn block large
                 class="elevation-0 rounded-0 text-bold"
                 color="success"
                 @click="change_password()" :loading="password.loading">
            ثبت تغیرات
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
  </v-container>

  </template>
<style>
.submit-box{
  position: relative;
  margin-top: -1.8em;margin-right: 2em;
}
.phone_input{
  direction: ltr;
}
@media (max-width: 767px) {
  .submit-box{
    margin-right: 0;
  }
}
</style>
  <script>
  
    import axios from "axios";
    export default {
      name: 'information_form',
      props:['user'],
      data(){
        return {
          token: localStorage.getItem('token'),
          errors: null,
          btn_loading: false,
          password : {old: null, new_1: null, new_2: null, loading: false, en: false, errors:[]},
          pass_show: {old:false, new_1: false, new_2: false}
        }
      },
      created() {
      },
      methods:{
        change_password(){
      this.password.loading = true;
      const password = new FormData();
      password.append('old_password', this.password.old);
      password.append('new_password', this.password.new_1);
      axios.put(`${this.BACKEND_LINK}/api/auth/change-password/`, password,{
        headers: {'Authorization': `Token ${this.token}`}
      }).then((response)=>{
        this.$snotify.success('گذرواژه با موفقیت ویرایش شد. برای مشاهده تغیرات دوباره وارد شوید.', 'ویرایش اطلاعات');
        this.password = {old: null, new_1: null, new_2: null, en: false, loading: false, errors: []};
      }).catch((error)=>{
        this.$snotify.error(`مشکلی در ویرایش گذرواژه وجود دارد.`, 'ویرایش اطلاعات');
        this.password.errors = error.response.data;
      }).finally(()=>{
        this.password.loading = false;
      })

    },

        send_data(){
          this.btn_loading = true;
          axios
                .put(`${this.BACKEND_LINK}/api/users/info/`, this.user, {
                  headers: {'Authorization': `Token ${this.token}`}
                })
                .then((response)=>{
                  if(response.status === 200){
                    this.user = response.data;
                    this.$snotify.success(`اطلاعات با موفقیت ذخیره شد`);
                  }
                  else{
                    this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                  }
                })
                .catch((error)=>{
                  this.errors = error.response.data.detail;
                  this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                })
                .finally(()=>{
                  this.btn_loading = false;
                })
        }
      },
    }
  </script>
  