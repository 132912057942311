<template>
  <div>
    <v-container class="mt-10">
      <v-card outlined :loading="!profile.menu">
        <v-card-title>
          منو
          <v-btn v-if="!profile.menu" @click="menu_add_dialog.en = true;" outlined color="#00519a" class="mx-5">
            افزودن
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
              <v-card
                  class="mx-auto rounded-xl"
                  width="100%"
                  height="850px"
                  outlined
              >
                <v-card-text>
                  <v-row v-for="(product, index) in profile.menu">
                    <v-col cols="1">
                      <small>{{index}}.</small>
                    </v-col>
                    <v-col cols="6">
                      <h2>{{product.name}} : {{product.value}}</h2>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions style="position: absolute;bottom:0;width: 100%;">
                  <div style="width: 100%;">
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                            color="primary"
                            outlined block
                            class="rounded-xl"
                            @click="image_edit_dialog.en=true;profile.menu.push({ name: null, value: null });image_edit_dialog.form=profile.menu;"
                        >
                          ویرایش
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-actions>
              </v-card>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="menu_add_dialog.en" scrollable height="10vh" width="30em">
      <v-card>
        <v-card-title>
          <h3 class="mx-auto">افزودن منو جدید</h3>
        </v-card-title>
        <v-card-text>
          <v-row style="padding: 0; margin: 0;" v-for="(product, index) in profile.menu" :key="index">
            <v-col cols="1">
              <div class="mt-3 text-center" style="font-size: 8px;display: grid">{{ index + 1 }}.</div>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  dense hide-details filled
                  v-model="profile.menu[index]['name']"
                  placeholder="نام"
                  @keyup="addNewRow(index)"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <div class="mt-3 text-center" style="font-size: 30px;color: red">:</div>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  dense hide-details filled
                  v-model="profile.menu[index]['value']"
                  placeholder="مقدار"
                  @keyup="addNewRow(index)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
<!--        <v-card-actions>-->
<!--          <v-btn block large-->
<!--                 v-else-->
<!--                 class="elevation-0 mb-1 text-bold"-->
<!--                 color="success"-->
<!--                 @click="close_create()"-->
<!--                 :loading="menu_add_dialog.loading"-->
<!--                 :disabled="menu_add_dialog.loading"-->
<!--          >-->
<!--            ثبت-->
<!--          </v-btn>-->
<!--        </v-card-actions>-->
      </v-card>
    </v-dialog>
    <v-dialog v-model="image_edit_dialog.en" scrollable height="10vh" width="30em">
      <v-card>
        <v-card-title>
          <h3 class="mx-auto">ویرایش منو</h3>
        </v-card-title>
        <v-card-text>
          <v-row style="padding: 0; margin: 0;" v-for="(product, index) in profile.menu" :key="index">
            <v-col cols="1">
              <div class="mt-3 text-center" style="font-size: 8px;display: grid">{{ index + 1 }}.</div>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  dense hide-details filled
                  v-model="profile.menu[index]['name']"
                  placeholder="نام"
                  @keyup="addNewRow(index)"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <div class="mt-3 text-center" style="font-size: 30px;color: red">:</div>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  dense hide-details filled
                  v-model="profile.menu[index]['value']"
                  placeholder="مقدار"
                  @keyup="addNewRow(index)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn block large
                 class="elevation-0 mb-1 text-bold"
                 color="success"
                 @click="update_menu()" :loading="image_edit_dialog.loading">
            ویرایش
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'menu_form',
  props:['profile'],
  data(){
    return {
      token: localStorage.getItem('token'),
      send_images:[],
      errors: null,
      btn_loading: false,
      slide_created:false,
      slide_id:null,
      social_delete_dialog:{
        image:{},
        en: false,
        loading: false,
      },
      image_edit_dialog:{
        en:false,
        loading:false,
        form: {},
      },
      menu_add_dialog:{
        en:false,
        loading:false,
        form: {},
      },
      old_img:null,
      slde_id:null,
    }
  },
  created() {

  },
  methods:{
    update_menu(){
      this.image_edit_dialog.loading = true;
      this.profile.menu.pop()
      axios
          .put(`${this.BACKEND_LINK}/api/users/profile/`, {
            menu:this.profile.menu
          }, {
            headers: {'Authorization': `Token ${this.token}`}
          })
          .then((response)=>{
            if(response.status === 200){
              this.user = response.data;
              this.$snotify.success(`منو با موفقیت ویرایش شد.`);
              this.image_edit_dialog.en = false;
              this.get_data();
            }
            else{
              this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
            }
          })
          .catch((error)=>{
            this.errors = error.response.data.detail;
            this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
          })
          .finally(()=>{
            this.image_edit_dialog.loading = false;
          })
    },
    addNewRow(index) {
      if(this.profile.menu.length === index+1){
        if(this.profile.menu[index].name && this.profile.menu[index].value){
          this.profile.menu.push({name:null, value:null})
        }
      }else{
        if(!this.profile.menu[index].name && !this.profile.menu[index].value){
          if(!this.profile.menu[index].name && !this.profile.menu[index].value){
            this.$delete(this.profile.menu, index);
          }else{
            this.profile.menu.pop();
          }
        }
      }
    },
    close_create(){
      this.menu_add_dialog.en=false;
    },
  },
}
</script>
