<template>
  <div style="max-width:100%;background: #F9F9F9">
    <card_nav v-if="nav"/>
    <card_detail @remove_nav="remove_nav"/>
  </div>
</template>

<script>
import card_nav from '@/components/card/nav.vue';
import card_detail from '@/components/card/detail.vue';

export default {
  name: 'Card',
  components: {card_nav, card_detail},
  data() {
    return {
      nav:true,
    }
  },//end data
  methods:{
    remove_nav(is_remove){
      if (is_remove){
        this.nav = false;
      }
    }
  }
}
</script>
