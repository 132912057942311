<template>
  <div v-if="user">
    <card_wall :bg="user.background"/>
    <v-avatar width="10em" height="10em" style="margin-right: calc(50% - 5em);margin-top: -5em">
      <v-img class="profile_picture" width="10em" height="10em" :src="user.profile_picture"></v-img>
    </v-avatar>
    <div class="text-center">
      <h2>{{ `${user.user.first_name} ${user.user.last_name}` }}</h2>
      <h4 style="color: rgba(0,0,0,0.7)" class="mt-2">{{ user.job }}</h4>
      <h5 style="color: rgba(0,0,0,0.6)" dir="ltr">
        {{ user.user.phone_number }}
      </h5>
      <v-row class="ma-5" style="justify-content: center;">
        <v-col cols="2">
          <svg @click="open_social(`tel:${user.user.phone_number}`)" style="cursor: pointer;"
              width="3em" height="3em" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="56" height="56" rx="15" fill="url(#paint0_linear_162_2511)" fill-opacity="0.08"/>
            <rect x="0.5" y="0.5" width="55" height="55" rx="14.5" stroke="url(#paint1_linear_162_2511)" stroke-opacity="0.65"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.6244 28.63C23.3056 33.9473 22.099 27.7958 18.7125 31.1799C15.4477 34.4438 13.5712 35.0978 17.7077 39.2331C18.2258 39.6495 21.5179 44.6592 33.0872 33.0931C44.658 21.5255 39.6512 18.2301 39.2347 17.7121C35.0882 13.5653 34.4455 15.4527 31.1807 18.7166C27.7942 22.1022 33.9431 23.3127 28.6244 28.63Z" fill="url(#paint2_linear_162_2511)"/>
            <defs>
              <linearGradient id="paint0_linear_162_2511" x1="0" y1="28.1179" x2="55.861" y2="28.1179" gradientUnits="userSpaceOnUse">
                <stop stop-color="#146EBF"/>
                <stop offset="1" stop-color="#00519A"/>
              </linearGradient>
              <linearGradient id="paint1_linear_162_2511" x1="0" y1="28.1179" x2="55.861" y2="28.1179" gradientUnits="userSpaceOnUse">
                <stop stop-color="#146EBF"/>
                <stop offset="1" stop-color="#00519A"/>
              </linearGradient>
              <linearGradient id="paint2_linear_162_2511" x1="40.6667" y1="28.0535" x2="15.3963" y2="28.0535" gradientUnits="userSpaceOnUse">
                <stop stop-color="#146EBF"/>
                <stop offset="1" stop-color="#00519A"/>
              </linearGradient>
            </defs>
          </svg>
        </v-col>
        <v-col cols="2" v-for="social in JSON.parse(user.social_links)">
          <div>
              <v-img width="3em" height="3em"
                     @click="open_social(social.link);"
                     :src="social.img.img"
                     style="cursor: pointer;"
                     class="rounded-lg mx-auto"
              ></v-img>
          </div>
        </v-col>
      </v-row>
      <br>
      <a :href="`${BACKEND_LINK}/api/cards/contact-file/${user.user.id}/`">
        <v-btn color="primary" outlined x-large class="rounded-lg px-5">
          <img class="inline ml-3 " width="30px" :src="`${'https://api.carocard.ir'}/static/user/home-user/images/call.svg`">
          ذخیره مخاطب
        </v-btn>
      </a>
      <br>

      <div class="text-right container" style="color: #165B99;font-size: 20px;">
        <h3>درباره من:</h3>
        <p class="mr-10" style="text-align: justify;">{{ user.about }}</p>
      </div>

      <div class="ma-5">
        <v-row v-if="images">
          <v-col v-for="image in images" cols="6" md="3">
            <v-card class="rounded-lg elevation-10" color="white" width="100%" height="20em">
              <div class="pa-3">
                <v-img :src="image.image"
                       width="100%"
                       class="rounded-lg"
                       height="10em"
                ></v-img>
              </div>
              <v-card-text class="text-right">
                <h3>
                  {{ image.title.length>30?image.title.slice(0,30)+'...':image.title }}
                </h3>
                <small>
                  {{ image.description.length>100?image.description.slice(0,100)+'...':image.description }}
                </small>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-for="image in slides"
           xs="6" sm="6" md="6" lg="6" xl="6" xxl="6"
                        >
                            <v-sheet
                                class="mx-auto rounded-xl"
                                width="100%"
                                height="auto"
                                outlined
                            >
                                <v-carousel>
                                  <v-carousel-item
                                    v-for="img in image.images"
                                    :src="`${BACKEND_LINK}${img.image}`"
                                    cover
                                  ></v-carousel-item>
                                </v-carousel>
                                <v-card-title class="text-white">
                                    {{ image.title }}
                                </v-card-title>
                                <v-card-text>
                                        {{ image.description }}
                                </v-card-text></v-sheet></v-col>
        </v-row>
      </div>
      <location v-if="user.location" :user="user" class="ma-10"></location>
      <br><br>
      <menu_ class="mt-10" :user="user"/>
    </div>
    <br><br>
    <v-footer color="black">
      <br>
    </v-footer>
  </div>
</template>

<script>
import card_wall from '@/components/card/wall.vue';
import location from "@/components/card/location.vue";
import menu_ from "@/components/card/menu.vue";
import axios from "axios";

export default {
  name: 'card_detail',
  components:{card_wall,location, menu_},
  data() {
    return {
      token: localStorage.getItem('token'),
      user:null,
      images:null,
      slides:[] ,
    }
  },//end data
  created() {
    this.get_detail()
    this.get_images();this.get_slides();
  },
  methods:{
    open_social(link){
      window.open(link, '_blank');
    },
    get_slides(){
            this.btn_loading = true;
            axios
                .get(`${this.BACKEND_LINK}/api/images/slides/?username=${this.$route.params.username}`)
                .then((response)=>{
                  if(response.status === 200){
                    this.slides = response.data;
                  }
                })
                .catch((error)=>{
                  this.$snotify.error(`مشکلی در دریافت اطلاعات پیش آمد`, 'خطا');
                })
                .finally(()=>{
                  this.btn_loading = false;
                })

        },
    get_images(){
      axios
          .get(`${this.BACKEND_LINK}/api/cards/${this.$route.params.username}/images/`)
          .then((response)=>{
            this.images = response.data;
          })
    },
    get_detail(){
      axios
          .get(`${this.BACKEND_LINK}/api/cards/${this.$route.params.username}`)
          .then((response)=>{
            this.user = response.data;
            if(response.data.user_code == '318' || response.data.user_code == '209' || response.data.user_code == '300'){
              this.$emit('remove_nav', true);
            }
          })
    }
  },//end methods

}
</script>

<style>
@media (max-width: 767px) {
  .profile_picture {
    width: 5em;
    height: 5em;

    max-width: 7em;
    max-height: 7em;
  }
}
</style>
