<template>
  <div>
    <div class="mx-auto" style="width: 100%; height: 300px;">
      <mapir :apiKey="mapirToken" :center="user.location">
        <mapAttributionControl />
        <mapNavigationControl position="top-right" />
        <mapNavigationControl position="top-left" />
        <mapGeolocateControl position="top-right" />
        <mapScaleControl position="top-right" />
        <mapGeolocateControl position="top-left" />
        <mapScaleControl position="top-left" />

        <mapMarker
            :coordinates.sync="user.location"
            color="white"
            :draggable="false"
        />
      </mapir>
      <br>
      <a rel="nofollow" class="external free" :href="`geo:${user.location[1]},${user.location[0]}?q=${user.location[1]},${user.location[0]}(${user.user.first_name} ${user.user.last_name})`">
        <v-btn outlined color="primary" class="rounded-lg px-10" large>
        مسیر یابی
        </v-btn>
      </a>
    </div>
  </div>
</template>

<script>
import {
  mapir,
  mapMarker,
  mapNavigationControl,
  mapGeolocateControl,
  mapScaleControl
} from "mapir-vue";

export default {
  name: "location",
  props:['user'],
  components: {
    mapir,
    mapMarker,
    mapNavigationControl,
    mapGeolocateControl,
    mapScaleControl,
  },
  created() {
    this.user.location = [parseFloat(this.user.location.split(',')[0]), parseFloat(this.user.location.split(',')[1])];
  },
  data() {
    return {
      loc:[],
      mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjUxMDk1NzJlZmM0YmRjZjllOWFhNTIyMTdlODg5YjZiMGZmNTY5ZjliYTkxZjIwN2UzODNkNjgxNDE0NGVhMDNmOTUyZjE0OTk5OWU3NDgzIn0.eyJhdWQiOiIyNDAwMiIsImp0aSI6IjUxMDk1NzJlZmM0YmRjZjllOWFhNTIyMTdlODg5YjZiMGZmNTY5ZjliYTkxZjIwN2UzODNkNjgxNDE0NGVhMDNmOTUyZjE0OTk5OWU3NDgzIiwiaWF0IjoxNjkzOTE3NjI0LCJuYmYiOjE2OTM5MTc2MjQsImV4cCI6MTY5NjUwOTYyNCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.OKblZVLaDF0I-urKeUIMnnZp7dgiQoXY5YgnqXASeb4vFgVa3vEdRHdjm_kprukWRW0cDkHLagmMn2zD52a3S3nL7Aoy4C8WnR31E435_cyVE2P0bpw58-2uX4yvcoKUa_36tR4WKop26XwSLUgH0Ab2JSsreDuxwcT0g6dmMaYmfDSpf3Xxk3Lb9QFp8fv2Rdnp-d-jKg0JiESLkx_luNoVJIH7NDD284Tie9a0Fwd_Xa3iiybsdHlGoViQi9OR7bNePp-tK4HHtjLrCJfvZk0fpbE95snC5TpF4e8b_3kNgfmiHzBGjQ6COxwlAI8A5QQQtnwhURC8BHM6V2cqJg"
    };
  }
};
</script>
