<template>
    <v-container class="mt-10">
        <v-card outlined :loading="btn_loading">
            <v-card-title >
                نمایش عمومی
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" xs="6" sm="6" md="4" lg="4" xl="4" xxl="4">
                            نمایش عمومی پروفایل من :
                    </v-col>
                    <v-col>
                        <v-container fluid>
                            <v-radio-group v-model="profile.is_public" @change="change_public()" inline>
                                <v-row>
                                    <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                                        <v-radio color="success" label="نمایش عمومی پروفایل من" :value="true"></v-radio>
                                    </v-col>
                                    <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6"><v-radio color="red"
                                            label="هیچکس قادر به مشاهده پروفایل من نباشد" :value="false"></v-radio></v-col>
                                </v-row>
                            </v-radio-group>
                        </v-container>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-container>
</template>
<style>
.submit-box {
    position: relative;
    margin-top: -1.8em;
    margin-right: 2em;
}

.phone_input {
    direction: ltr;
}

@media (max-width: 767px) {
    .submit-box {
        margin-right: 0;
    }
}
</style>
<script>

import axios from "axios";
export default {
    name: 'public_profile_form',
    props: ['profile'],
    data() {
        return {
            token: localStorage.getItem('token'),
            errors: null,
            btn_loading: false,
        }
    },
    created() {
        this.get_socials()
    },
    methods: {
        change_public() {
            this.btn_loading = true;
            axios
                .put(`${this.BACKEND_LINK}/api/users/profile/`, { is_public: this.profile.is_public }, {
                    headers: { 'Authorization': `Token ${this.token}` }
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.profile = response.data;
                        this.$snotify.success(`اطلاعات با موفقیت ذخیره شد`);
                    }
                    else {
                        this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.detail;
                    this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                })
                .finally(() => {
                    this.btn_loading = false;
                })
        }
    },
}
</script>
  