<template>
    <v-container class="mt-10">
        <v-card outlined :loading="!user_socials" class="pb-10">
            <v-card-title>
                شبکه‌های اجتماعی
                <v-btn @click="social_dialog.en = true;" outlined color="#00519a" class="mx-5">
                    افزودن
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row class="ma-5">
                    <v-col cols="2" v-for="social in user_socials">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-badge color="primary" @click.native="social_detail_dialog.en=true;social_detail_dialog.social=social" style="cursor: pointer;">
                                        <template v-slot:badge >
                                            <v-icon color="white">mdi-pencil</v-icon>
                                        </template>
                                        <v-avatar style="margin: -10px;">
                                            <v-img :src="social.img.img"></v-img>
                                        </v-avatar>
                                    </v-badge>
                                </div>
                            </template>
                            <span>{{social.link}}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
        <div class="submit-box">
            <v-btn
                style="border: 10px white solid;background: linear-gradient(98deg, rgb(21, 110, 191) 18.24%, rgb(0, 81, 154) 79.84%);"
                dark rounded x-large size="x-large" flat class="my-auto" outlined :loading="btn_loading" @click="send_socials()">تایید شبکه‌ها</v-btn>
        </div>

        <v-dialog v-model="social_dialog.en" scrollable height="10vh" width="40em">
            <v-card>
                <v-card-title>
                    <h3 class="mx-auto">افزودن شبکه اجتماعی</h3>
                </v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-container>
                        <v-row>
                            <v-col cols="9">
                            <v-text-field v-model="social_dialog.social_link"></v-text-field>
                            </v-col>
                        <v-col cols="3">
                            <v-autocomplete menue return-object hide-no-data hide-details :items="socials" v-model="social_dialog.social_img" label="شبکه">
                            <template v-slot:selection="{ item }">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-img :src="item.img"></v-img>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </template>

                            <template v-slot:item="{ props, item }">
                                <v-list-item v-bind="props" v-if="item.img" @click="social_dialog.social_img = item">
                                    <v-list-item-avatar>
                                        <v-img :src="item.img"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                        </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn block large class="elevation-0 rounded-0 text-bold" color="success" @click="add_social()"
                        :loading="social_dialog.loading">
                        افزودن لینک
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="social_detail_dialog.en" height="10vh" width="40em">
            <v-card>
                <v-card-title>
                    <h3 class="mx-auto">ویرایش شبکه اجتماعی</h3>
                </v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    v-model="social_detail_dialog.social.link"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-row class="ma-1">
                        <v-col class="text-center">
                            <v-btn block large class=" text-bold" color="success" @click="edit_social(social_detail_dialog.social)"
                                :loading="social_detail_dialog.loading">
                                ویرایش
                            </v-btn>
                        </v-col>
                        <v-col class="text-center">
                            <v-btn block large dark class=" text-bold" color="red" @click="remove_social(social_detail_dialog.social)"
                                :loading="social_detail_dialog.loading">
                                حذف این لینک
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<style>
.submit-box {
    position: relative;
    margin-top: -1.8em;
    margin-right: 2em;
}

.phone_input {
    direction: ltr;
}

@media (max-width: 767px) {
    .submit-box {
        margin-right: 0;
    }
}
</style>
<script>

import axios from "axios";
export default {
    name: 'social_form',
    props: ['user_socials'],
    data() {
        return {
            token: localStorage.getItem('token'),
            errors: null,
            btn_loading: false,
            socials: [],
            social_dialog: {
                en: false,
                loading: false,
                social_link: null,
                social_img: {},
            },
            social_detail_dialog:{
                en: false,
                loading: false,
                social:{}
            }
        }
    },
    created() {
        this.get_socials()
    },
    methods: {
        arrayRemove(arr, value) {
                return arr.filter(function(i){
                    return i !== value;
                })
        },
        remove_social(social){
            this.user_socials = this.arrayRemove(this.user_socials , social);
            this.social_detail_dialog.en = false;
        },
        add_social(){
            this.user_socials.push({
                link: this.social_dialog.social_link,
                img : this.social_dialog.social_img
            });
            this.social_dialog.en = false;
        },
        edit_social(social){
            this.user_socials = this.arrayRemove(this.user_socials , social);
            this.user_socials.push(social);
            this.social_detail_dialog.en = false;
        },
        get_socials() {
            axios
                .get(`${this.BACKEND_LINK}/api/socials/`, {
                    headers: { 'Authorization': `Token ${this.token}` }
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.socials = response.data;
                        if(this.socials){
                            this.social_dialog.social_img = this.socials[0];
                        }
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data;
                })
        },
        send_socials() {
            this.btn_loading = true;
              axios
                    .put(`${this.BACKEND_LINK}/api/users/profile/`, {social_links:JSON.stringify(this.user_socials)}, {
                      headers: {'Authorization': `Token ${this.token}`}
                    })
                    .then((response)=>{
                      if(response.status === 200){
                        this.profile = response.data;
                        this.$snotify.success(`اطلاعات با موفقیت ذخیره شد`);
                      }
                      else{
                        this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                      }
                    })
                    .catch((error)=>{
                      this.errors = error.response.data.detail;
                      this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                    })
                    .finally(()=>{
                      this.btn_loading = false;
                    })
        }
    },
}
</script>
