<template>
    <div>
        <v-container class="mt-10">
            <v-card outlined :loading="!slides">
                <v-card-title>
                  اسلاید ها
                    <v-btn @click="image_add_dialog.en = true;" outlined color="#00519a" class="mx-5">
                        افزودن
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row v-if="slides">
                      
                        <v-col v-for="image in slides"
                        cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6"
                        >
                        <v-card
                                class="mx-auto rounded-xl"
                                width="100%"
                                height="850px"
                                outlined
                            >
                                <v-carousel>
                                  <v-carousel-item
                                    v-for="img in image.images"
                                    :src="`${BACKEND_LINK}${img.image}`"
                                    cover
                                  ></v-carousel-item>
                                </v-carousel>
                                <v-card-title class="text-white">
                                  {{ image.title }}
                                </v-card-title>
                                <v-card-text>
                                        {{ image.description }}
                                    </v-card-text>
                                    <v-card-actions style="position: absolute;bottom:0;width: 100%;">
                                        <div style="width: 100%;">
                                        <v-divider class="mx-2 my-3"></v-divider>
                                        <v-row>
                                        <v-col cols="6">
                                            <v-btn
                                                color="red"
                                                text block
                                                class="rounded-xl"
                                                @click="social_delete_dialog.en=true;social_delete_dialog.image=image"
                                            >
                                                حذف
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                color="primary"
                                                outlined block
                                                class="rounded-xl"
                                                @click="image_edit_dialog.en=true;image_edit_dialog.form=image;old_img = image.image;"
                                            >
                                                ویرایش
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    </div>

                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
        <v-dialog v-model="image_add_dialog.en" scrollable height="10vh" width="30em">
            <v-card>
                <v-card-title>
                <h3 class="mx-auto">افزودن اسلاید جدید</h3>
                </v-card-title>
                <v-card-text v-if="slide_created">
                  تصاویر را به ترتیب آپلود کنید و در پایان ثبت را کلیک کنید.
                  <v-file-input v-model="image_add_dialog.form.image"
                    @change="send_image"
                         label="تصویر"
                    ></v-file-input>
                </v-card-text>
                <v-card-text v-else>
                <v-divider></v-divider>
                <v-container>
                    <v-text-field v-model="image_add_dialog.form.title"
                        rounded outlined label="عنوان"
                    ></v-text-field>
                    <v-textarea v-model="image_add_dialog.form.description"
                        rounded outlined label="توضیحات"
                    ></v-textarea>
                </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn block large
                        class="elevation-0 mb-1 text-bold"
                        color="primary"
                        @click="send_slide()" :loading="image_add_dialog.loading"
                        v-if="!slide_created"
                        >
                        ادامه
                </v-btn>
                    <v-btn block large
                    v-else
                        class="elevation-0 mb-1 text-bold"
                        color="success"
                        @click="close_create()"
                           :loading="image_add_dialog.loading"
                           :disabled="image_add_dialog.loading"
                        >
                        ثبت
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="image_edit_dialog.en" scrollable height="10vh" width="30em">
            <v-card>
                <v-card-title>
                <h3 class="mx-auto">ویرایش تصویر</h3>
                </v-card-title>
                <v-card-text>
                <v-divider></v-divider>
                <v-container>
                    <v-text-field v-model="image_edit_dialog.form.title"
                        rounded outlined label="عنوان"
                    ></v-text-field>
                    <v-textarea v-model="image_edit_dialog.form.description"
                        rounded outlined label="توضیحات"
                    ></v-textarea>
                </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn block large
                        class="elevation-0 mb-1 text-bold"
                        color="success"
                        @click="update_image()" :loading="image_edit_dialog.loading">
                    ویرایش
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      <v-dialog v-model="social_delete_dialog.en" height="10vh" width="40em">
        <v-card>
          <v-card-title>
            <h3 class="mx-auto">حذف تصویر</h3>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-container>
              <h3>آیا از حذف تصویر زیر مطمئن هستید؟</h3>
                <v-img
                    class="ma-5"
                    :src="social_delete_dialog.image.image"
                ></v-img>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row class="ma-1">
              <v-col class="text-center">
                <v-btn block large class=" text-bold" color="success" @click="social_delete_dialog.en=false"
                       :loading="social_delete_dialog.loading">
                  انصراف
                </v-btn>
              </v-col>
              <v-col class="text-center">
                <v-btn block large outlined dark class=" text-bold" color="red" @click="delete_image(social_delete_dialog.image.id)"
                       :loading="social_delete_dialog.loading">
                  بله حذف شود
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
</template>
<style>
.submit-box{
  position: relative;
  margin-top: -1.8em;margin-right: 2em;
}
.phone_input{
  direction: ltr;
}
@media (max-width: 767px) {
  .submit-box{
    margin-right: 0;
  }
}
</style>
  <script>

    import axios from "axios";
    export default {
      name: 'slide_form',
      data(){
        return {
          token: localStorage.getItem('token'),
          send_images:[],
          errors: null,
          btn_loading: false,
          slide_created:false,
          slide_id:null,
          slides: [],
          social_delete_dialog:{
            image:{},
            en: false,
            loading: false,
          },
          image_edit_dialog:{
            en:false,
            loading:false,
            form: {},
          },
          image_add_dialog:{
            en:false,
            loading:false,
            form: {},
          },
          old_img:null,
          slde_id:null,
        }
      },
      created() {
        this.get_data();
      },
      methods:{
        close_create(){
          this.image_add_dialog.en=false;
          this.get_data();
        },
        get_data(){
            this.btn_loading = true;
            axios
                .get(`${this.BACKEND_LINK}/api/images/slides/`, {
                  headers: {'Authorization': `Token ${this.token}`}
                })
                .then((response)=>{
                    this.slides = response.data;
                    this.image_add_dialog.form = {};
                    this.image_add_dialog.en = false;
                })
                .catch((error)=>{
                  this.$snotify.error(`مشکلی در دریافت اطلاعات پیش آمد`, 'خطا');
                })
                .finally(()=>{
                  this.btn_loading = false;
                })

        },
        delete_image(id){
          this.social_delete_dialog.loading = true;
          axios
              .delete(`${this.BACKEND_LINK}/api/images/slides/?slide_id=${id}`, {
                headers: {'Authorization': `Token ${this.token}`}
              })
              .then((response)=>{
                  this.$snotify.success(response.data.message);
                  this.get_data();
                  this.social_delete_dialog.en = false;
                
              })
              .catch((error)=>{
                this.errors = error.response.data.detail;
                this.$snotify.error(`${error.response.data}مشکلی پیش آمد کد`, 'خطا');
              })
              .finally(()=>{
                this.social_delete_dialog.loading = false;
              })
        },
        update_image(){
          this.image_edit_dialog.loading = true;
          let edit_data = new FormData()
          edit_data.append('title', this.image_edit_dialog.form.title)
          edit_data.append('description', this.image_edit_dialog.form.description)
          axios
                .put(`${this.BACKEND_LINK}/api/images/slides/?slide_id=${this.image_edit_dialog.form.id}`, edit_data, {
                  headers: {'Authorization': `Token ${this.token}`}
                })
                .then((response)=>{
                  if(response.status === 200){
                    this.user = response.data;
                    this.$snotify.success(`تصویر با موفقیت ویرایش شد.`);
                    this.image_edit_dialog.en = false;
                    this.get_data();
                  }
                  else{
                    this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                  }
                })
                .catch((error)=>{
                  this.errors = error.response.data.detail;
                  this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                })
                .finally(()=>{
                  this.image_edit_dialog.loading = false;
                })
        },
        send_image(){
          if(this.image_add_dialog.form.image) {
            this.image_add_dialog.loading = true;
            let data = new FormData();
            data.append('image', this.image_add_dialog.form.image)
            data.append('slide', parseInt(this.slide_id))
            axios
                .post(`${this.BACKEND_LINK}/api/images/slide-image/create/`, data, {
                  headers: {'Authorization': `Token ${this.token}`}
                })
                .then((response) => {
                  if (response.status === 201) {
                    this.$snotify.success(`تصویر با موفقیت اضافه شد. تصویر بعدی را اضافه ویا ثبت کنید`);
                    this.image_add_dialog.form = {};
                  }
                })
                .catch((error) => {
                  this.$snotify.error(error.response.data, 'خطا');
                })
                .finally(() => {
                  this.image_add_dialog.loading = false;
                });
          }
        },
        send_slide(){
          this.image_add_dialog.loading = true;
          let data = new FormData();
          //data.append('images', JSON.stringify(this.send_images))
          //for (let i = 0; i < this.send_images.length; i++) {
           // data.append("images[]", this.send_images[i]);
           // alert(i)
          //}
          data.append('title', this.image_add_dialog.form.title)
          data.append('description', this.image_add_dialog.form.description)
          axios
                .post(`${this.BACKEND_LINK}/api/images/slide/`, data, {
                  headers: {'Authorization': `Token ${this.token}`}
                })
                .then((response)=>{
                  if(response.status === 200){
                    this.slide_id = response.data.id;
                    this.slide_created=true;
                    }
                  else{
                    //this.$snotify.error(response.data);
                    this.$snotify.error(`${response.status}`, 'خطا');
                  }
                  this.$snotify.success(`اسلاید با موفقیت اضافه شد.`);

                })
                .catch((error)=>{
                  this.errors = error.response.data.detail;
                  this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                 // this.$snotify.error(error.response.data);
                })
                .finally(()=>{
                  this.image_add_dialog.loading = false;
                })
        }

      },
    }
  </script>
