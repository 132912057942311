<template>
    <v-container class="mt-10">
      <v-card outlined :loading="!profile" class="pb-10">
        <v-card-title>
          اطلاعات بیشتر :
        </v-card-title>
        <v-card-text>
          <v-row v-if="profile">
            <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                <v-text-field v-model="profile.address" label="آدرس" outlined
                              :error="!!(errors&&errors.address)" :error-messages="errors && errors.address?errors.address:[]"
                ></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
              <v-text-field v-model="profile.job" label="شغل" outlined
                            :error="!!(errors&&errors.job)" :error-messages="errors && errors.job?errors.job:[]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="profile.about"
                            :error="!!(errors&&errors.about)" :error-messages="errors && errors.about?errors.about:[]"
                            label="درباره‌من" outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6" class="text-center">
                <v-avatar width="20em" height="20em">
                    <v-img :src="profile.profile_picture"></v-img>
                </v-avatar>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                <v-img :src="profile.background"></v-img>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                <v-file-input
                    outlined v-model="updated_profile.profile_picture"
                    label="پروفایل"
                    :error="!!(errors&&errors.profile_picture)"
                ></v-file-input>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                <v-file-input
                    outlined v-model="updated_profile.background"
                    label="تصویر زمینه"
                    :error="!!(errors&&errors.background)"
                ></v-file-input>
            </v-col>
            <v-col cols="12">
              <!-- @change_location="set_location"  -->
              <v-row>
                <v-col cols="12" xs="6" sm="6" md="4" lg="4" xl="4" xxl="4">
                        نمایش مکان من :
                </v-col>
                <v-col>
                    <v-container fluid>
                        <v-radio-group v-model="is_public_loc" @change="update_is_pub_loc" inline>
                            <v-row>
                                <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                                    <v-radio color="success" label="نمایش مکان" :value="true"></v-radio>
                                </v-col>
                                <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6"><v-radio color="red"
                                        label="نمایش نده" :value="false"></v-radio></v-col>
                            </v-row>
                        </v-radio-group>
                    </v-container>
                </v-col>
            </v-row>

          <div id="app">
            <mapir :center="location" :apiKey="mapirToken"
            >
              <mapNavigationControl position="top-right" />
              <mapNavigationControl position="top-left" />
              <mapGeolocateControl position="top-right" />
              <mapScaleControl position="top-right" />
              <mapGeolocateControl position="top-left" />
              <mapScaleControl position="top-left" />
              <mapMarker
                  :coordinates.sync="location"
                  color="white"
                  :draggable="true"
                  @dragend="update_loc"
              />
            </mapir>
          </div>
          {{ profile.location }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="submit-box">
          <v-btn
                  style="border: 10px white solid;background: linear-gradient(98deg, rgb(21, 110, 191) 18.24%, rgb(0, 81, 154) 79.84%);"
                  dark rounded x-large size="x-large" flat class="my-auto" outlined @click="send_data()"
                  :loading="btn_loading"
                  >
                ثبت اطلاعات
          </v-btn>

      </div>
    </v-container>
  </template>
<style>
.submit-box{
  position: relative;
  margin-top: -1.8em;margin-right: 2em;
}
@media (max-width: 767px) {
  .submit-box{
    margin-right: 0;
  }
}
</style>
  <script>
import location_form from "@/components/form/location_form.vue";
import {
  mapir,
  mapMarker,
  mapNavigationControl,
  mapGeolocateControl,
  mapScaleControl
} from "mapir-vue";
    import axios from "axios";
    export default {
      name: 'more_information_form',
      props:['profile'],
      components:{    
        mapir,
        mapMarker,
        mapNavigationControl,
        mapGeolocateControl,
        mapScaleControl,
      },
      data(){
        return {
          token: localStorage.getItem('token'),
          mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjUxMDk1NzJlZmM0YmRjZjllOWFhNTIyMTdlODg5YjZiMGZmNTY5ZjliYTkxZjIwN2UzODNkNjgxNDE0NGVhMDNmOTUyZjE0OTk5OWU3NDgzIn0.eyJhdWQiOiIyNDAwMiIsImp0aSI6IjUxMDk1NzJlZmM0YmRjZjllOWFhNTIyMTdlODg5YjZiMGZmNTY5ZjliYTkxZjIwN2UzODNkNjgxNDE0NGVhMDNmOTUyZjE0OTk5OWU3NDgzIiwiaWF0IjoxNjkzOTE3NjI0LCJuYmYiOjE2OTM5MTc2MjQsImV4cCI6MTY5NjUwOTYyNCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.OKblZVLaDF0I-urKeUIMnnZp7dgiQoXY5YgnqXASeb4vFgVa3vEdRHdjm_kprukWRW0cDkHLagmMn2zD52a3S3nL7Aoy4C8WnR31E435_cyVE2P0bpw58-2uX4yvcoKUa_36tR4WKop26XwSLUgH0Ab2JSsreDuxwcT0g6dmMaYmfDSpf3Xxk3Lb9QFp8fv2Rdnp-d-jKg0JiESLkx_luNoVJIH7NDD284Tie9a0Fwd_Xa3iiybsdHlGoViQi9OR7bNePp-tK4HHtjLrCJfvZk0fpbE95snC5TpF4e8b_3kNgfmiHzBGjQ6COxwlAI8A5QQQtnwhURC8BHM6V2cqJg",
          location:null,
          is_public_loc:false,
          updated_profile:{
              background:null,
              profile_picture:null
          },
          errors: null,
          btn_loading: false,
        }
      },
      created(){
        this.is_public_loc = this.profile.is_public_loc;
        this.location = [parseInt(this.profile.location.split(',')[0]), parseInt(this.profile.location.split(',')[1])];
      },
      methods:{
        update_is_pub_loc(){
          this.profile.is_public_loc = this.is_public_loc;
        },
        update_loc(e){
          // this.profile.is_public_loc=loc.is_public_loc;
          this.profile.location=`${e.marker._lngLat.lng},${e.marker._lngLat.lat}`;
        },
        set_location(location){
          console.log(location)
          this.profile.location = location.toString();
        },
        send_data(){
          this.btn_loading = true;
          let data = new FormData();
          data.append('address', this.profile.address)
          data.append('job', this.profile.job)
          data.append('about', this.profile.about)
          data.append('location', this.profile.location)
          data.append('is_public_loc', this.profile.is_public_loc)
          if(this.updated_profile.background){
            data.append('background', this.updated_profile.background)
          }if(this.updated_profile.profile_picture){
            this.$emit('update_profile');
            data.append('profile_picture', this.updated_profile.profile_picture)
          }
          data.append('is_public', this.profile.is_public)

          axios
                .put(`${this.BACKEND_LINK}/api/users/profile/`, data, {
                  headers: {'Authorization': `Token ${this.token}`}
                })
                .then((response)=>{
                  if(response.status === 200){
                    this.profile = response.data;
                    this.$snotify.success(`اطلاعات با موفقیت ذخیره شد`);
                  }
                  else{
                    this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                  }
                })
                .catch((error)=>{
                  this.errors = error.response.data.detail;
                  this.$snotify.error(`مشکلی پیش آمد کد`, 'خطا');
                })
                .finally(()=>{
                  this.btn_loading = false;
                })
        }
      },
    }
  </script>
