import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        themes: {
            light: {
                background: '#92BFD9',
            }
        },
        options: {
            customProperties: true
        },
        defaultAssets: {
            font: {
                family: 'IRANSansXBlack',
            },
        },
    },

});
