import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css';


Vue.config.productionTip = true
if(Vue.config.productionTip) {
  Vue.prototype.BACKEND_LINK = 'https://api.carocard.ir';
}else{
  Vue.prototype.BACKEND_LINK = 'http://localhost:8000';
}

const options = {
  bodyMaxLength: 500,
  toast: {
    bodyMaxLength: 500,
    position: SnotifyPosition.rightTop,
  }
}

Vue.use(Snotify, options)

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
