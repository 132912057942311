<template>
  <nav style="position: fixed">
    <v-row style="max-height: 5em">
      <v-col cols="5" class="nav-item">
          <div class="nav-welcome">به کاروکارت خوش آمدید!</div>
      </v-col>
      <v-col cols="2" class="nav-item-icon">
        <v-img width="4em" height="4em" class=" mx-auto align-middle" src="https://carocard.ir/static/user/home-user/images/logo.svg"/>
      </v-col>
      <v-col cols="5" class="nav-item">
        <div class="centered-container">
          <span>ارتباط با ما:</span>
          <a href="https://instagram.com/carocard_" target="_blank">
            <v-img class="nav-item-social-icon" src="https://api.carocard.ir/static/user/home-user/images/Instagram.svg"/>
          </a>
          <a href="https://wa.me/+989186657485" target="_blank">
            <v-img class="nav-item-social-icon" src="https://api.carocard.ir/static/user/home-user/images/Whatsapp.svg"/>
          </a>
          <a href="http://T.me/Caroaso" target="_blank">
            <v-img class="nav-item-social-icon" src="https://api.carocard.ir/static/user/home-user/images/Telegram.svg"/>
          </a>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </nav>
</template>
<style>
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.3em;
}
.nav-welcome{
  font-weight: bold;font-size: 16px;
  margin-top: 1em;
}
.nav-item{
  position: relative;
  top: 50%;
}
.nav-item-icon{
  font-size: 16px;
}
.nav-item{
  text-align: center;
  font-size: 16px;
}
@media (max-width: 767px) {
  .nav-item{
    font-size: 12px;
  }
  .nav-item-icon{
    font-size: 12px;
  }
  .nav-welcome{
    font-size: 14px;
  }
  .nav-item-social-icon{
    width: 3em;
  }
}
nav{
  color: #e9cd92;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);


  position: absolute;
  width:100%;
  top: 0;
  z-index: 999;

}
</style>
<script>
export default {
  name: 'card_nav',
  data() {
    return {

    }
  },
  created() {
  },
  methods: {

  },
}
</script>
