<template>
  <div>
    <v-row>
          <v-col cols="12" xs="6" sm="6" md="4" lg="4" xl="4" xxl="4">
                  نمایش مکان من :
          </v-col>
          <v-col>
              <v-container fluid>
                  <v-radio-group v-model="profile.is_public_loc" @change="update" inline>
                      <v-row>
                          <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                              <v-radio color="success" label="نمایش مکان" :value="true"></v-radio>
                          </v-col>
                          <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6"><v-radio color="red"
                                  label="نمایش نده" :value="false"></v-radio></v-col>
                      </v-row>
                  </v-radio-group>
              </v-container>
          </v-col>
      </v-row>

    <div id="app">
      <mapir :center="location" :apiKey="mapirToken"
      >
        <mapNavigationControl position="top-right" />
        <mapNavigationControl position="top-left" />
        <mapGeolocateControl position="top-right" />
        <mapScaleControl position="top-right" />
        <mapGeolocateControl position="top-left" />
        <mapScaleControl position="top-left" />
        <mapMarker
            :coordinates.sync="location"
            color="white"
            :draggable="true"
            @dragend="update"
        />
      </mapir>
    </div>
  </div>
</template>
<style>
#app {
  width: 100%;
  height: 50vh;
}
#center-marker {
}
</style>
<script>
import {
  mapir,
  mapMarker,
  mapNavigationControl,
  mapGeolocateControl,
  mapScaleControl
} from "mapir-vue";
import axios from "axios";

export default {
  name: 'more_information_form',
  props:['is_public_loc', 'profile'],
  components: {
    mapir,
    mapMarker,
    mapNavigationControl,
    mapGeolocateControl,
    mapScaleControl,
  },
    data() {
      return {
        location: null,
        mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjUxMDk1NzJlZmM0YmRjZjllOWFhNTIyMTdlODg5YjZiMGZmNTY5ZjliYTkxZjIwN2UzODNkNjgxNDE0NGVhMDNmOTUyZjE0OTk5OWU3NDgzIn0.eyJhdWQiOiIyNDAwMiIsImp0aSI6IjUxMDk1NzJlZmM0YmRjZjllOWFhNTIyMTdlODg5YjZiMGZmNTY5ZjliYTkxZjIwN2UzODNkNjgxNDE0NGVhMDNmOTUyZjE0OTk5OWU3NDgzIiwiaWF0IjoxNjkzOTE3NjI0LCJuYmYiOjE2OTM5MTc2MjQsImV4cCI6MTY5NjUwOTYyNCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.OKblZVLaDF0I-urKeUIMnnZp7dgiQoXY5YgnqXASeb4vFgVa3vEdRHdjm_kprukWRW0cDkHLagmMn2zD52a3S3nL7Aoy4C8WnR31E435_cyVE2P0bpw58-2uX4yvcoKUa_36tR4WKop26XwSLUgH0Ab2JSsreDuxwcT0g6dmMaYmfDSpf3Xxk3Lb9QFp8fv2Rdnp-d-jKg0JiESLkx_luNoVJIH7NDD284Tie9a0Fwd_Xa3iiybsdHlGoViQi9OR7bNePp-tK4HHtjLrCJfvZk0fpbE95snC5TpF4e8b_3kNgfmiHzBGjQ6COxwlAI8A5QQQtnwhURC8BHM6V2cqJg",
      };
    },
  created() {
    this.location = [parseInt(this.profile.location.split(',')[0]), parseInt(this.profile.location.split(',')[1])];
  },
  methods:{
    update(){
      this.$emit('update', {
        loc:this.location,
        is_public_loc:this.profile.is_public_loc
      })
    }
    // change_is_public_loc(){
    //   this.$emit('change_is_public_loc', this.is_public_loc)
    // },
    // getlatLng(e){
    //   this.$emit('change_location', `${e.marker._lngLat.lng},${e.marker._lngLat.lat}`)
    // },
  }
};

</script>
