<template>
  <v-main>
    <v-container class="text-center">
      <h1>صفحه اصلی</h1>
      <v-btn @click="$router.push({name: 'login'})" color="success">
        ورود
      </v-btn>
    </v-container>
  </v-main>
</template>

<script>

  export default {
    name: 'Home',
    components: {},
  }
</script>
