<template>
  <div class="container mw-100" >
    <v-row class="mx-auto">
      <v-col cols="6" sm="4" md="4" lg="3" xl="3"
             class="text-center mx-auto"
             v-for="f in user.menu">
        <v-card  class="card elevation-0 rounded-1_5em" width="100%">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <v-card-title>
              {{ f.name }}
          </v-card-title>
          <v-divider class="mx-5"></v-divider>
          <v-card-text style="color: #e9cd92">
              {{ f.value }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'menu_',
  props:['user'],
  data() {
    return {

    }
  },
}
</script>
<style>
.rounded-1_5em{
  border-radius: 1.5em !important;
}
.card {
  position: relative;
  display: inline-block;
  color: #e9cd92;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-size: 24px;
  overflow: hidden;
  transition: 0.2s;
}



.card:hover {
  color: #443925;
  transition-delay: 1s;
}


.card span {
  position: absolute;
  display: block;
}




.card span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #e9cd92);
}



.card:hover span:nth-child(1) {
  left: 100%;
  transition: 1s;
}


.card span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #e9cd92);
}

.card:hover span:nth-child(3) {
  right: 100%;
  transition: 1s;
  transition-delay: 0.5s;
}



.card span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #e9cd92);
}

.card:hover span:nth-child(2) {
  top: 100%;
  transition: 1s;
  transition-delay: 0.25s;
}



.card span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #e9cd92);
}

.card:hover span:nth-child(4) {
  bottom: 100%;
  transition: 1s;
  transition-delay: 0.75s;
}

</style>