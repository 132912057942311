<template>
  <v-app>
    <vue-snotify></vue-snotify>
    <router-view/>
  </v-app>
</template>

<style>
@import '@/assets/style.css';
</style>
<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created(){},
};
</script>
