<template>
  <v-main>
    <nav style="background: linear-gradient(98deg, #156EBF 18.24%, #00519A 79.84%);display: flex">
      <v-avatar class="ma-1 pa-1" style="border: solid 2px red">
          <v-img width="100%" height="100%" :src="profile.profile_picture"/>
      </v-avatar>
        <span style="color: rgb(255, 255, 255);font-weight: bold;" class="my-auto text-white">{{`${user.first_name} ${user.last_name} ` }}</span>
        <small style="color: rgb(203 213 225)" class="my-auto mr-3" >عزیز خوش آمدید</small>
      <v-spacer></v-spacer>
      <v-btn :loading="logout_loading" @click="logout" outlined color="red" class="my-auto ml-4">
        <svg class="svg-icon ml-1" style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M835.669333 554.666667h-473.173333A42.453333 42.453333 0 0 1 320 512a42.666667 42.666667 0 0 1 42.474667-42.666667h473.173333l-161.813333-161.834666a42.666667 42.666667 0 0 1 60.330666-60.330667l234.666667 234.666667a42.666667 42.666667 0 0 1 0 60.330666l-234.666667 234.666667a42.666667 42.666667 0 0 1-60.330666-60.330667L835.669333 554.666667zM554.666667 42.666667a42.666667 42.666667 0 1 1 0 85.333333H149.525333C137.578667 128 128 137.578667 128 149.482667v725.034666C128 886.4 137.6 896 149.525333 896H554.666667a42.666667 42.666667 0 1 1 0 85.333333H149.525333A106.816 106.816 0 0 1 42.666667 874.517333V149.482667A106.773333 106.773333 0 0 1 149.525333 42.666667H554.666667z" fill="" /></svg>
        خروج
      </v-btn>
    </nav>
    <v-container class="mt-5">
      <information_form :user="user"/>
      <more_information_form @update_profile="get_profile()" :profile="profile"/>
      <social_form :user_socials="profile.social_links?JSON.parse(profile.social_links):[]"/>
      <public_profile_form :profile="profile"/>
      <images_form />
      <slide_form v-if="profile.is_slide_active"/>
      <menu_form :profile="profile" v-if="profile.is_menu_active"/>
    </v-container>
  </v-main>
</template>

<script>

  import axios from "axios";
  import information_form from "@/components/form/information_form.vue"
  import more_information_form from "@/components/form/more_information_form.vue"
  import social_form from "@/components/form/social_form.vue"
  import public_profile_form from "@/components/form/public_profile_form.vue"
  import images_form from "@/components/form/images_form.vue"
  import slide_form from "@/components/form/slide.vue"
  import menu_form from "@/components/form/menu.vue"

  export default {
    name: 'Dashboard',
    components: {
      information_form,
      more_information_form,
      social_form,
      public_profile_form,
      images_form,
      slide_form,
      menu_form
    },
    data(){
      return {
        token: localStorage.getItem('token'),
        profile: null,
        user: null,
        logout_loading:false,
      }
    },
    created() {
      this.get_profile()
    },
    methods:{
      logout(){
        this.logout_loading = true;
        axios
            .post(`${this.BACKEND_LINK}/api/auth/logout/`,{},{
              headers: {'Authorization': `Token ${this.token}`}
            })
            .then((response)=>{
              if(response.status === 200){
                this.$router.push({name:'login'})
              }
            })
            .finally(()=>{
              this.logout_loading = false;
            })
      },
      get_profile(){
        axios
            .get(`${this.BACKEND_LINK}/api/users/info/`,{
              headers: {'Authorization': `Token ${this.token}`}
            })
            .then((response)=>{
              this.user = response.data;
            })
            .catch((error)=>{
              if (error.response.status === 401) {
                this.$router.push({name:'login'});
              }
            });
        axios
            .get(`${this.BACKEND_LINK}/api/users/profile/`,{
              headers: {'Authorization': `Token ${this.token}`}
            })
            .then((response)=>{
              this.profile = response.data;
            })
            .catch((error)=>{
              if (error.response.status === 401) {
                this.$router.push({name:'login'});
              }
          })
      }
    },
  }
</script>
