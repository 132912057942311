import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import DashboardView from "@/views/DashboardView.vue";
import Card from "@/views/Card.vue";
import RedirectCard from "@/views/RedirectCard.vue"
import Admin from "@/views/Admin.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/user/:username',
    name: 'Card',
    component: Card
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/:user_code',
    name: 'redirect_to_card',
    component: RedirectCard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
